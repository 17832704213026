import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import useStyles from './styles';

type Props = {
  backgroundColor:
    | 'white'
    | 'green'
    | 'blue'
    | 'greenGradient'
    | 'greenGradientAbTest'
    | 'linear'
    | 'lightGrey';
  verticalPadding?: number;
  withOverflowHidden?: boolean;
  className?: string;
};

const Article: FC<PropsWithChildren<Props & HTMLAttributes<HTMLElement>>> = ({
  backgroundColor = 'white',
  verticalPadding = 120,
  withOverflowHidden = true,
  className,
  children,
  ...rest
}) => {
  const { classes, cx } = useStyles({ verticalPadding });

  return (
    <div
      className={cx(
        classes[backgroundColor],
        withOverflowHidden && classes.withOverflow,
        className
      )}
    >
      <article className={cx(classes.container)} {...rest}>
        {children}
      </article>
    </div>
  );
};

export default Article;
